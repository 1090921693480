import React, { createContext, useEffect, useState } from 'react';
import { DeleteAccountLoading } from 'src/screens';
import { getFeatureFlags } from 'src/utils/featureFlags';

export const FeatureFlagContext = createContext<{
  flags: { useNewInstantInkScreenOnDeleteAccount?: boolean };
}>({ flags: {} });

export const FeatureFlagProvider = ({ children }) => {
  const [flags, setFlags] = useState({});
  const [flagsAreLoading, setFlagsAreLoading] = useState(true);

  useEffect(() => {
    const fetchFeatureFlag = async () => {
      const ffValues = await getFeatureFlags([
        { key: 'useNewInstantInkScreenOnDeleteAccount', defaultValue: false }
      ]);
      setFlags(ffValues);
      setFlagsAreLoading(false);
    };

    fetchFeatureFlag();
  }, []);

  if (flagsAreLoading) {
    return <DeleteAccountLoading />;
  }

  return (
    <FeatureFlagContext.Provider value={{ flags }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
