export const getFeatureFlags = async (flags = []) => {
  if (!flags.length) return {};
  const values = {};
  try {
    const client = await window.Shell.v1.featureFlags.getClient('ucde-portal');
    for (const flag of flags) {
      const { key, defaultValue = false } = flag;
      if (!key) continue;
      try {
        const value = await client.getFeatureFlag({
          key,
          defaultValue
        });
        values[key] = value;
      } catch (error) {
        values[key] = defaultValue;
      }
    }
  } catch (error) {
    for (const flag of flags) {
      const { key, defaultValue = false } = flag;
      if (!key) continue;
      values[key] = defaultValue;
    }
  }
  return values;
};
