import React, { ReactNode } from 'react';
import { CustomText } from './styles';

export enum TextTypes {
  generic,
  paragraph
}

type TextPropsType = {
  children: ReactNode;
  type?: TextTypes.generic | TextTypes.paragraph;
};

export const Text = ({ children, type }: TextPropsType) => {
  return (
    <CustomText
      data-testid={
        type === TextTypes.paragraph ? 'text-paragraph' : 'text-generic'
      }
      className={type === TextTypes.paragraph ? 'label paragraph' : 'label'}
    >
      {children}
    </CustomText>
  );
};
