import styled from 'styled-components';

export const CustomText = styled.p`
  && {
    line-height: 24px;

    &.paragraph {
      margin-bottom: 18px;
    }
  }
`;
